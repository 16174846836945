<template>
  <v-card 
    outlined 
    flat
    class="my-2"
    elevation="0"
  >
    <v-card-title class="pa-1">
      <v-list-item class="px-0">
        <v-list-item-avatar class="mx-1">
          <v-icon
            color="primary"
            left
            class="mt-n1"
          >
            mdi-account-circle-outline
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title style="white-space: normal !important">
            <span v-if="typeUser === 'ven'" class="primary--text font-weight-bold text-h6">{{user.ven_des}} - ({{user.co_ven}}) </span>
            <span v-if="typeUser === 'admin'" class="primary--text font-weight-bold text-h6">{{user.name}} </span>
          </v-list-item-title>
          <v-list-item-subtitle v-if="typeUser === 'ven' && user.user">
            <v-chip
            label
            class="font-weight-medium mt-1"
            :color="user.sesion_activa ? 'icono' : 'red'"
            x-small
            dark
            v-text="user.sesion_activa ? 'Sesion Activa' : 'Sesion Cerrada'"
          />               
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="typeUser === 'admin'">
            <v-chip
            label
            class="font-weight-medium mt-1"
            :color="user.sesion_activa ? 'icono' : 'red'"
            x-small
            dark
            v-text="user.sesion_activa ? 'Sesion Activa' : 'Sesion Cerrada'"
          />               
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="mx-0">
          <slot name="action"></slot>
        </v-list-item-action>
      </v-list-item>
    </v-card-title>
    <v-divider />
    <v-card-text v-if="typeUser === 'ven' && user.user">
      <v-row>
        <v-col cols="12">
          <v-card flat color="grey lighten-5 pa-3">
            <v-card-text class="py-0">
              <v-row>
                <v-col cols="12" sm="4">
                  <span class="caption text-uppercase d-block blue--text text--darken-3">
                    Usuario/Rol
                  </span>
                  <v-list-item class="px-0">           
                    <v-list-item-content class="pt-1">
                      <v-list-item-title>
                        <v-icon v-if="user.user" small color="success" left>mdi-shield-check</v-icon>
                        <span 
                          class="blue-grey--text font-weight-medium" 
                          v-text="user.user ? user.user.email : '' " 
                        />
                      </v-list-item-title>
                      <v-list-item-subtitle class="mt-1" v-if="user.user">
                        <v-chip label color="grey lighten-2" small class="mr-2" v-for="(rol,i) in user.user.roles" :key="'rol-'+i">
                          <span class="font-weight-medium"> {{rol.name}} </span>
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>    
                </v-col>
                <v-col cols="12" sm="4" v-if="user.ultimo_uso">
                  <span class="caption text-uppercase d-block blue--text text--darken-3">
                    Última Sesión
                  </span>
                  <v-icon small color="blue-grey" left class="mt-n1">mdi-alarm</v-icon>
                  <span class="blue-grey--text">{{user.ultimo_uso | FullDate}}</span>
                </v-col>
                <v-col cols="12" sm="4">
                  <span class="caption text-uppercase d-block blue--text text--darken-3">
                    Estatus
                  </span>
                  <v-chip
                    class="my-2 font-weight-bold"
                    :color="user.user.status  === true ? 'success-alert' : 'disabled-alert' "
                    small
                    label
                    pill
                    dark
                    v-if="user.user"
                  >
                    {{ user.user.status === true ? 'Activo' : 'Inactivo' }}
                  </v-chip>
                  <v-chip
                    label
                    outlined
                    class="my-2 mx-5 font-weight-bold"
                    color="red"
                    small
                    v-else
                  >
                    S/U
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="typeUser === 'admin'">
      <v-row>
        <v-col cols="12">
          <v-card flat color="grey lighten-5 pa-3">
            <v-card-text class="py-0">
              <v-row>
                <v-col cols="12" sm="4">
                  <span class="caption text-uppercase d-block blue--text text--darken-3">
                    Usuario/Rol
                  </span>
                  <v-list-item class="px-0">           
                    <v-list-item-content class="pt-1">
                      <v-list-item-title>
                        <v-icon small color="success" left>mdi-shield-check</v-icon>
                        <span 
                          class="blue-grey--text font-weight-medium" 
                          v-text="user.email" 
                        />
                      </v-list-item-title>
                      <v-list-item-subtitle class="mt-1">
                        <v-chip label color="grey lighten-2" small class="mr-2" v-for="(rol,i) in user.roles" :key="'rol-'+i">
                          <span class="font-weight-medium"> {{rol.name}} </span>
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>    
                </v-col>
                <v-col cols="12" sm="4" v-if="user.ultimo_uso">
                  <span class="caption text-uppercase d-block blue--text text--darken-3">
                    Última Sesión
                  </span>
                  <v-icon small color="blue-grey" left class="mt-n1">mdi-alarm</v-icon>
                  <span class="blue-grey--text">{{user.ultimo_uso | FullDate}}</span>
                </v-col>
                <v-col cols="12" sm="4">
                  <span class="caption text-uppercase d-block blue--text text--darken-3">
                    Estatus
                  </span>
                  <v-chip
                    class="my-2 font-weight-bold"
                    :color="user.status === true ? 'success-alert' : 'disabled-alert' "
                    small
                    label
                    pill
                    dark
                    v-if="user.user"
                  >
                    {{ user.status === true ? 'Activo' : 'Inactivo' }}
                  </v-chip>
                  <v-chip
                    label
                    outlined
                    class="my-2 mx-5 font-weight-bold"
                    color="red"
                    small
                    v-else
                  >
                    S/U
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card> 
</template>
<script>
export default {
  name:'ListMobile',
  props:{
    user:{
      type: Object,
      default: () => ({}),
    },
    typeUser: String
  }
}
</script>