<template>
  <v-list two-line>
    <!-- <v-list-item style="min-height:44px !important" >
      <v-list-item-content>
        <v-list-item-title 
          class="primary--text font-weight-bold display-1"                   
        >
          <v-icon class="mt-n1" color="primary" left>mdi-account</v-icon>
          <span  v-text="user.ven_des" /> 
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
    <v-list-item>
      <v-list-item-avatar>
        <v-icon
          class="grey lighten-1"
          dark
        >
          mdi-account-tie
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle class="blue-grey--text text--darken-3">Cliente</v-list-item-subtitle>
        <v-list-item-title class="font-weight-bold primary--text" v-text="cliente.cli_des"></v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="d-flex flex-row align-center">
        <v-chip
          label
          color="blue-grey lighten-5"
          class="pa-1"
        >

          <v-icon 
            size="20" 
            :color="cliente.lat !== null && cliente.lng !== null ? 'icono' : 'blue-grey lighten-3'" 
            class="mx-2"
            v-text="cliente.lat !== null && cliente.lng !== null ? 'mdi-map-marker-check' : 'mdi-map-marker-off' "
          ></v-icon>

          <v-icon 
            size="20" 
            :color="cliente.visita_programadas !== null ? 'icono' : 'blue-grey lighten-3'" 
            class="mx-2"
            v-text="cliente.visita_programadas !== null ? 'mdi-alarm-check' : 'mdi-alarm-off' "
          ></v-icon>
        </v-chip>
        <v-divider class="ml-2" vertical inset></v-divider>
        <slot name="action"></slot>
      </v-list-item-action>
    </v-list-item>
    <v-list-item class="mx-4">
      <v-list-item-content>
        <v-row>
          <v-col cols="6" class="d-flex align-center">
            <span class="blue-grey--text text--darken-3">Código:</span>
          </v-col>
          <v-col cols="6">
            <span 
              class="font-weight-bold primary--text d-flex justify-end align-center" 
              v-text="cliente.co_cli"
            />
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="grey lighten-4 mx-4">
      <v-list-item-content>
        <v-row>
          <v-col cols="6" class="d-flex align-center">
            <span class="blue-grey--text text--darken-3">RIF:</span>
          </v-col>
          <v-col cols="6">
            <span 
              class="font-weight-bold primary--text d-flex justify-end align-center" 
              v-text="cliente.rif"
            />
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-list-item class="mx-4">
      <v-list-item-content>
         <v-row>
          <v-col cols="6" class="d-flex align-center">
            <span class="blue-grey--text text--darken-3">Vendedor:</span>
          </v-col>
          <v-col cols="6">
            <span 
              class="font-weight-bold primary--text d-flex justify-end" 
              v-text="cliente.ven_des"
            />
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item> -->
    <v-divider />
  </v-list>
</template>
<script>
export default {
  name:'MobileClients',
  props:{
    cliente:{
      type: Object,
      default: () => ({}),
    }
  }
}
</script>