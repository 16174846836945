<template>
  <v-list two-line>
    <v-list-item>
      <v-list-item-content>        
        <v-list-item-title>
          <span class="blue-grey--text text--darken-3 ml-3">Pedido </span>
          <span class="text-h4 font-weight-bold primary--text"> #<span v-text="pedido.Numero"></span></span>          
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <slot name="action"></slot>
      </v-list-item-action>
    </v-list-item>
    <v-list-item class="grey lighten-4 mx-4">
      <v-list-item-content>
        <v-row>
          <v-col cols="6" class="d-flex align-center">
            <span class="blue-grey--text text--darken-3">Cliente:</span>
          </v-col>
          <v-col cols="6">
            <span 
              class="font-weight-bold primary--text d-flex justify-end align-center" 
              v-text="pedido.cli_des"
            />
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="mx-4">
      <v-list-item-content>
        <v-row>
          <v-col cols="6" class="d-flex align-center">
            <span class="blue-grey--text text--darken-3">Fecha:</span>
          </v-col>
          <v-col cols="6">
            <span 
              class="font-weight-bold primary--text d-flex justify-end align-center" 
            >
              {{ pedido.Fecha | smartDate }}
            </span>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="mx-4 grey lighten-4">
      <v-list-item-content>
        <v-row>
          <v-col cols="6" class="d-flex align-center">
            <span class="blue-grey--text text--darken-3">Procesado:</span>
          </v-col>
          <v-col cols="6" class="text-right">
           <v-chip 
              small 
              :color="pedido.Procesado ==='N' ? 'red' : 'success'" 
              dark v-text="pedido.Procesado"
            ></v-chip>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="mx-4">
      <v-list-item-content>
        <v-row>
          <v-col cols="6" class="d-flex align-center">
            <span class="blue-grey--text text--darken-3">Precio Venta:</span>
          </v-col>
          <v-col cols="6" class="text-right">
             <span 
              class="font-weight-bold primary--text d-flex justify-end align-center" 
            >
              Nro. {{pedido.TipoPrecio | prec_venta}}
            </span>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="grey lighten-4 mx-4">
      <v-list-item-content>
        <v-list-item-subtitle class="blue-grey--text text--darken-3 pb-2">Observaciones</v-list-item-subtitle>
        <v-list-item-title class="font-weight-bold primary--text" v-text="pedido.Observaciones"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mt-3"/>
  </v-list>
</template>
<script>
export default {
  name: 'MobilePedidos',
   props:{
    pedido:{
      type: Object,
      default: () => ({}),
    }
  },
  filters:{
    prec_venta(price){
      const numbreLista = price.split('');
      return numbreLista[1];
    }
  }
}
</script>