<template>
  <v-col cols="12" sm="6" :md="type ==='grid' ? 4 : 6" :lg="type ==='grid' ? 4 : 6" :xl="type ==='grid' ? 4 : 6">
    <v-card outlined>
      <!-- <v-img
      height="250px"
      contain
       :src="require(`@/assets/Products/Product_${parseInt(Math.random() * (4 - 1) + 1)}.jpg`)"
      ></v-img> -->
      <v-img
        height="250px"
        contain
        :src="`${dir_product + articulo.co_art}.jpg`"
      />
      <v-card-title style="height: 100px !important" class="display-1" v-text="articulo.art_des" />
      <v-card-subtitle v-text="articulo.co_art+' - '+articulo.lin_des" />
      <v-card-text class="px-6">
        <v-row>
          <v-col cols="6">
            <span class="d-block blue-grey--text text--darken-3">Precio:</span>
          </v-col>
          <v-col cols="6" class="text-right">
            <span 
              class="font-weight-bold primary--text" 
              :style="colorPrecio"
            >
              {{moneda}} {{pVenta | FormatCurrency}}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <span class="d-block blue-grey--text text--darken-3">Stock:</span>
          </v-col>
          <v-col cols="6" class="text-right">
            <span 
              class="font-weight-bold primary--text" 
              v-text="articulo.stock_act"
            />
          </v-col>
         </v-row>
        <v-row>
          <v-col cols="6">
            <span class="d-block blue-grey--text text--darken-3">Peso</span>
          </v-col>
          <v-col cols="6" class="text-right">
            <span 
              class="font-weight-bold primary--text" 
              v-text="articulo.peso"
            />
          </v-col>
         </v-row>
        <v-row>
          <v-col cols="6">
            <span class="d-block blue-grey--text text--darken-3">Unid. de venta</span>
          </v-col>
          <v-col cols="6" class="text-right">
            <span 
              class="font-weight-bold primary--text" 
              v-text="articulo.uni_venta"
            />
          </v-col>
         </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end blue-grey">
        <slot name="action"></slot>        
      </v-card-actions>
    </v-card>    
  </v-col> 
</template>
<script>
export default {
  name:'MobileInventario',
  props:{
    articulo:{
      type: Object,
      default: () => ({}),
    },
    type: String,
    moneda: String,
    pVenta: {
      type: String,
      default: () => ('')
    },
    colorPrecio: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    dir_product: process.env.VUE_APP_BASE_INVENTARIO,
  })
}
</script>